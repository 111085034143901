export default ({ component, commit, respond, succeed, fail }) => {
  let animations = [];

  succeed(({ snapshot, effect }) => {
    let things: HTMLElement[] = Array.from(
      component.el.querySelectorAll(':scope [wire\\:animate-move]')
    );

    animations = things.map((thing) => {
      const oldRect = thing.getBoundingClientRect();

      return () => {
        const newRect = thing.getBoundingClientRect();

        thing.animate(
          [
            {
              transform: `translateX(${oldRect.left - newRect.left}px) translateY(${oldRect.top - newRect.top}px)`
            },
            { transform: 'translateX(0) translateY(0)' }
          ],
          { duration: 200, easing: 'ease-in-out' }
        );
      };
    });

    things.forEach((thing) => {
      thing.getAnimations().forEach((animation) => animation.finish());
    });

    queueMicrotask(() => {
      queueMicrotask(() => {
        while (animations.length) {
          animations.shift()();
        }
      });
    });
  });
};
