export default ({ el }) => {
  if (!el.hasAttribute('wire:fade-in')) {
    return;
  }

  const animation = el.animate([{ opacity: 0 }, { opacity: 1 }], {
    duration: 500,
    easing: 'ease-in-out'
  });
};
