import { gsap } from 'gsap';

import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

export default (config) => ({
  active: 0,

  total: 0,

  tween: null,

  init() {
    const images: HTMLElement[] = gsap.utils.toArray(
      this.$refs.images.children
    );

    this.total = images.length;

    this.$nextTick(() => {
      this.tween = gsap.to(images, {
        xPercent: -100 * (images.length - 1),
        ease: 'none',
        scrollTrigger: {
          refreshPriority: 10,
          trigger: this.$el,
          pin: true,
          start: 'center center',
          scrub: 1,
          snap: 0,
          end: () => '+=' + this.$refs.images.scrollWidth,
          onLeave: () => {
            this.active = this.total - 1;
          }
        }
      });

      images.forEach((image: HTMLElement, index: number) => {
        const trigger = image.querySelector('img');

        ScrollTrigger.create({
          refreshPriority: 0,
          containerAnimation: this.tween,
          scroller: this.$refs.images,
          trigger: trigger,
          horizontal: true,
          start: 'left center',
          toggleActions: 'play none none reverse',
          onEnter: ({ progress, direction, isActive }) => {
            this.active = index;
          },
          onLeaveBack: () => {
            this.active = Math.max(0, index - 1);
          }
        });
      });
    });
  },

  goTo(index: number) {
    this.active = index;

    const images: HTMLElement[] = gsap.utils.toArray(
      this.$refs.images.children
    );
    const target = images[index];
    const totalScroll =
      this.tween.scrollTrigger.end - this.tween.scrollTrigger.start;
    const totalMovement = (images.length - 1) * target.offsetWidth;

    let y = Math.round(
      this.tween.scrollTrigger.start +
        (target.offsetLeft / totalMovement) * totalScroll
    );

    gsap.to(window, {
      scrollTo: {
        y: y,
        autoKill: false
      },
      duration: 0.5
    });
  }
});
