function FadeOut({ swiper, on, extendParams }) {
  extendParams({
    fadeout: false,
    fadeOpacity: 0.4
  });

  const setOpacity = (swiper) => {
    const {
      width: swiperWidth,
      height: swiperHeight,
      slides,
      slidesSizesGrid,
      params
    } = swiper;

    const transform = swiper.translate;
    const center = -transform + swiperWidth / 2;

    for (let i = 0, length = slides.length; i < length; i += 1) {
      const slideEl = slides[i];
      const slideSize = slidesSizesGrid[i];
      const slideOffset = slideEl.swiperSlideOffset;
      const centerOffset = (center - slideOffset - slideSize / 2) / slideSize;
      const offsetMultiplier = centerOffset;

      let opacity = Math.max(
        1 - 0.6 * Math.abs(offsetMultiplier),
        params.fadeOpacity
      );

      slideEl.style.opacity = opacity;
    }
  };

  on('setTransition', () => {
    if (!swiper.params.fadeout) {
      return;
    }

    swiper.slides.forEach((el) => {
      el.style.transitionProperty = 'transform, opacity';
    });
  });

  on('setTranslate', () => {
    if (!swiper.params.fadeout) {
      return;
    }

    setOpacity(swiper);
  });
}

export { FadeOut as default };
