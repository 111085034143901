export default ({ component, commit, respond, succeed, fail }) => {
  succeed(({ snapshot, effect }) => {
    const container = component.el.querySelector(
      ':scope [wire-ref="team-members"]'
    );

    if (!container) {
      return;
    }

    const oldRect = container.getBoundingClientRect();

    const animation = () => {
      const newRect = container.getBoundingClientRect();

      container.animate(
        [{ height: `${oldRect.height}px` }, { height: `${newRect.height}px` }],
        { duration: 500, easing: 'ease-in-out' }
      );
    };

    container.getAnimations().forEach((animation) => animation.finish());

    queueMicrotask(() => {
      queueMicrotask(() => {
        animation();
      });
    });
  });
};
