import './_fonts';

import { livewire } from './alpine';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

livewire.hook('morph.updated', ({ el, component }) => {
  ScrollTrigger.refresh();
});

livewire.start();

import '../css/app.css';
