import {
  Alpine,
  Livewire
} from '../../../../vendor/livewire/livewire/dist/livewire.esm.js';

import Collapse from '@alpinejs/collapse';
import Focus from '@alpinejs/focus';
import Intersect from '@alpinejs/intersect';

Alpine.plugin(Collapse);
Alpine.plugin(Intersect);
Alpine.plugin(Focus);

/**
 * Custom Plugins
 */
import emerge from './_emerge';
import { default as header } from './_header';

Alpine.store('header', header);

/**
 * Reusable Data Objects
 */
import scrollTriggeredCarousel from './_scroll-triggered-carousel';
import slider from './_slider';

Alpine.plugin(emerge);

Alpine.data('slider', slider);
Alpine.data('scrollTriggeredCarousel', scrollTriggeredCarousel);

/**
 * Livewire Hooks
 */
import animateMove from './_animate-move';
import fadeIn from './_fade-in';
import teamMembersAnimation from './_team-members';

Livewire.hook('commit', teamMembersAnimation);
Livewire.hook('commit', animateMove);
Livewire.hook('morph.added', fadeIn);

export const livewire = Livewire;
